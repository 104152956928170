//
// breadcrumb.scss
//




// Breadcrumb item arrow
.breadcrumb-item {
  font-family: $font-family-primary;
  > a {
    color: var(--#{$variable-prefix}gray-700);
    font-family: $font-family-primary;
  }
  + .breadcrumb-item {
    &::before {
      font-family: $font-family-primary;
      font-size: 15px;
      line-height: 20px;
    }
  }
}
