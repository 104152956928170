.superior-university-logo{
    width: 40%;
}

.uol-logo{
    width: 30%;
}

@media (max-width: 768px) {
    .uol-logo{
        width: 50%;
    }
}