.cv{
    border: 1px solid black;
    border-radius: 5px;
    padding: 50px 50px 80px;
    text-align: left;
    width: 60%;


    .intro-text{
        margin-bottom: 0 !important;
    }

    .cv-section{
        margin-top: 10px;

        h3{
            margin-top: 14px;
            padding-bottom: 10px;
            border-bottom: 1px solid lightgray
        }
    }

    .work, .education, .skills, .languages{
        
        h5{
            font-weight: bold;   
            margin-top: 10px  
;
        }
        .name-location{
        margin-bottom: 0;       

        }
        .tenure,  .proficiency{
            opacity: 0.7;
        }

        ul{
            list-style-type: none;
            padding-left: 0;

        }
    }
}   