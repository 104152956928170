.training-details {
  .training-badge {
    background-color: #fff;
    // min-width: 350px;
    border: 1px solid $secondary;
    border-radius: 5px;

    .icon-img {
      width: 25px;
      height: 25px;
    }
    .schedule-detail-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 110px;
      overflow: hidden;
      border-radius: 50%;
      border: 2px solid $secondary;
    }
    .schedule-detail-avatar img {
      width: 110px;
      height: 110px;
      object-fit: cover;
    }
  }
  .training-badge-responsive {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;

    .icon-img {
      width: 25px;
      height: 25px;
    }
    .schedule-detail-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 110px;
      overflow: hidden;
      border-radius: 50%;
      border: 2px solid $secondary;
    }
    .schedule-detail-avatar img {
      width: 110px;
      height: 110px;
      object-fit: cover;
    }
  }
  .accordions-wrapper {
    background-color: #e4e4e4;
    border-radius: 5px;
  }
  .training-detail-avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid lightgray;
  }
  .training-detail-avatar img {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
}
