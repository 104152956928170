.job-search-area {
  margin-top: calc($header-height + calc(0.2 * $header-height));
}

.jobs-main-area {
  margin: 0 10px;
}

.filters-section,
.jobs-list-section,
.job-detail-section {
  font-family: $font-family-secondary;
  overflow-y: scroll;
  i {
    font-size: 1.2rem;
  }

  height: calc(100vh - calc(3 * $header-height));

  @media (max-width: 992px) {
    &.filters-section {
      height: 50px;
    }
  }
}
.accordion-item div.checkboxes:not(:last-child) {
  margin-bottom: 4px;
}
.pagination-separated ul.pagination {
  margin-bottom: 0;
}
.pages-option {line-height: 0.8; border-color: #3e6a9f;}

// .filters-section, .jobs-list-section, .job-detail-section{

//     height : calc(100vh - calc(2 * $header-height));
//     font-family : $font-family-secondary;
//     overflow-y: scroll;
//     i{
//         font-size: 1.2rem;
//     }
// }

/* Default styles for filters-section2 */
// .filters-section2 {
//     font-family: $font-family-secondary;
//     overflow-y: scroll;
//     i {
//         font-size: 1.2rem;
//     }
// }

/* Media query for small screens */
// @media (max-width: 767px) {
//     .filters-section2 {
//         height: 50px; /* Set your desired height for small screens */
//     }
// }

.career-section,
.jobs-list-section1 {
  height: calc(100vh - calc(2 * $header-height));
  font-family: $font-family-secondary;
  i {
    font-size: 1.2rem;
  }
}

.filters-outer-div {
  background-color: white;
}

.jobs-list-section {
  padding-top: 10px;
}

.images-div {
  position: relative;
  top: 0;
  left: 0;
}

.filter {
  border-bottom: 1px solid lightgray;
  padding: 10px 0;
}

.accordion-item {
  // border: none;
  //   margin-bottom: 20px !important;
  border-color: 1px solid black !important;
}
.accordion-button {
  //   border: 1px solid black !important;
  background-color: #fff !important;
  // background-color: rgb(243, 246, 249) !important;
  // background-color: $primary !important;

  // border-color: white !important;
  // border-width: 0 !important;
  // padding: var(--bs-accordion-btn-padding-y) 10px !important;
  color: $primary !important;
  // color: #fff !important;
  font-size: 1.2rem;
  font-weight: 800;
}
.accordion-button::after {
  color: #fff !important;
}

.accordion-button:not(.collapsed) {
  //   color: #000 !important;
  //   background-color: white;
}
.accordion-button:not(.collapsed)::after {
  color: #fff !important;
}
.ql-editor::before {
  font-style: normal !important; /* Set the font style to normal */
}

.form-range {
  appearance: auto !important;
  height: 0.1rem !important;
}

.job-component {
  text-align: left;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 20px;
  transition: 0.2s;
  margin-bottom: 10px;
  background-color: white;
  cursor: pointer;

  &:hover {
    background-color: #d3d3d34a;
  }

  &.active {
    background-color: #d3d3d34a;
  }

  ul {
    padding-left: 0;
    display: flex;
    list-style-type: none;
  }
  li {
    padding: 5px;
    font-size: 1rem;
    font-weight: 800;
    border-radius: 5px;
    margin-right: 10px;
    padding-left: 0;
  }

  li h5 {
    display: flex;
    align-content: center;
    align-items: flex-start;
    margin-bottom: 0;
  }

  li i {
    margin-right: 5px;
  }
  li {
    .bx-briefcase-alt-2 {
      color: $primary;
    }

    .bx-money {
      color: $success;
    }

    .la-users {
      color: $warning;
    }
    .la-clock {
      color: $info;
    }
    .la-graduation-cap {
      color: #fdc800;
    }
  }
}

.job-detail-section {
  .job-detail {
    background-color: white;
  }

  border: 1px solid lightgrey;
  border-radius: 5px;
  margin-top: 10px;
  padding: 0;

  .cover-photo {
    position: relative;
    top: 0;
    left: 0;
    border-radius: 5px 5px 0 0;
    width: 100%;
  }

  .logo {
    position: absolute;
    bottom: 0;
    left: 40px;
    width: auto;
    height: 80px;
  }

  h4 {
    font-weight: $font-weight-bold;
  }

  th {
    font-size: 1.1rem;
    font-weight: $font-weight-bolder;
  }

  p,
  td,
  .responsibilities {
    font-size: 1rem;
    font-weight: $font-weight-normal;
    list-style-position: outside;
  }

  Table {
    width: 50%;
  }
}

.job-characteristics {
  .type {
    .key {
      color: $primary;
    }
    .value {
      background-color: $primary;
    }
  }

  .degree {
    .key {
      color: #0ab39c;
    }
    .value {
      background-color: #0ab39c;
    }
  }

  .experience {
    .key {
      color: #299cdb;
    }
    .value {
      background-color: #299cdb;
    }
  }

  .positions {
    .key {
      color: #f7b84b;
    }
    .value {
      background-color: #f7b84b;
    }
  }

  .salary {
    .key {
      color: #fdc800;
    }
    .value {
      background-color: #fdc800;
    }
  }

  .key {
    font-weight: 800;
  }
  .value {
    padding: 6px;
    border-radius: 8px;
    color: white;
  }
}

#job-detail-page {
  margin-top: calc($header-height + calc(0.5 * $header-height));
}
