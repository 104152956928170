.counselling-gif,
.how-it-works-gif {
  width: 90%;
}

.counsellors-row {
  background-color: $primary;
}

.counsellor-info {
  transition: all 0.2s;

  img {
    cursor: pointer;
    transition: all 0.2s;
    height: 250px;
    margin-bottom: 20px;
    box-shadow: 4px 0px 12px 2px rgba(0, 0, 0, 0.25);
    border: 1px solid transparent;
    border-radius: 20px;
    &:hover {
      transform: scale(1.1);
    }
  }

  h4 {
    cursor: pointer;
    color: $primary;
    font-weight: bold;
    display: inline;
    margin-top: 15px;
    background-color: white;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 4px 0px 12px 2px rgba(0, 0, 0, 0.25);
  }
}

.disc-ul {
  list-style-type: disc;
  list-style-position: outside;

  li {
    margin-left: 1rem;
  }
}