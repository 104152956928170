// 
// _footer.scss
// 

.footer {
    padding: 20px calc(#{$grid-gutter-width} / 2);
    color: white;    
    width: 100%;
    height: $footer-height;
    background-color: $primary;

    @media (max-width: 991.98px) {
        left: 0;
    }

    a{
        color: white !important;
        margin-bottom: 5px;
        border-bottom: 1px solid white;
    }

    h5{
        color: white;
        font-weight: $font-weight-bold;
    }

    .container-fluid{
            padding: 0 100px;
    }
}

[data-layout="vertical"] {

    &[data-sidebar-size="sm"],
    &[data-sidebar-size="sm-hover"] {
        .footer {
            left: $vertical-menu-width-sm;

            @media (max-width: 767.98px) {
                left: 0;
            }
        }
    }
    &[data-sidebar-size="md"] {
        .footer {
            left: $vertical-menu-width-md;

            @media (max-width: 991.98px) {
                left: 0;
            }
        }
    }

}

[data-layout="horizontal"]{
    .footer {
        left: 0 !important;
    } 
}

[data-layout="vertical"][data-layout-style="detached"] {

    @media (min-width: 1024.1px) {
        .footer {
            left: 0 !important;
            background-color: transparent;
        } 
    }
}

[data-layout="twocolumn"] { 
    .footer {
        @media (min-width: 768.1px) {
            left: calc(#{$twocolumn-menu-iconview-width} + #{$twocolumn-menu-width});
        }
    }
}