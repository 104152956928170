.about-us-wrapper{
    margin-top: calc(1*$header-height);
    min-height: calc(100vh - $header-height);


    .main-text-div{
      display: flex;
      align-items: center ;
    }

    .main-img-div img{
      width: 100%;
      height: auto
    }

  }