.resource-profile{
    
    margin-top: 0.8rem;
    
    &:first-of-type{
        border-bottom: 1px solid $primary;
    }

    h2{
        text-align: left;
        
    }

    p{
       text-align: justify;
    }

    i.bx{
        color: $primary;
        background: rgb(245, 246, 255);
        border: 1px solid transparent;
        border-radius: 50%;
        transition: 0.2s ease-in;
        cursor: pointer;

        &:hover{
            background-color: $primary;
            color: white;
        }

    }

    i.bx-pencil{
        padding: 8px
    }
    
    i.bx-plus{
        padding: 5px;
    }
    
    i.la-times{
        
        padding: 8px;
        background: rgb(255 204 204 / 35%);
        border: 1px solid transparent;
        border-radius: 50%;
        transition: 0.2s ease-in;
        cursor: pointer;

        &:hover{
            background-color: $danger;
            color: white;
        }
    }

    .work-experience, .education{
        text-align: left;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    .designation, .institute{
     margin-bottom: 0.2rem;  
     font-weight: 600; 
    }

    .company-location, .degree, .location{
        margin-bottom: 0;
        font-weight: 500;
    }
    
    .tenure{
        opacity: 0.7;
        margin-bottom: 0;
        
    }

    .description{
        margin-top: 0.5rem;
    }
}