.skill-pills{
    display: inline;
    
    list-style-type: none;
    li{
    display: flex;
    float: left;
    padding: 10px;
    background: #dbf6ff;
    border-radius: 20px;    
    margin-right: 5px;
        .ri-close-line{
            cursor: pointer;
        }
    }
}

