.custom-footer {
  h4 {
    color: $primary;
    font-weight: $font-weight-medium;
    text-align: left;
  }

  ul {
    text-align: left;
    font-family: $font-family-primary;
    font-weight: $font-weight-semibold;
    font-size: 15px;
  }

  li {
    margin-bottom: 5px;
    cursor: pointer;
  }
}

.bottom-footer {
  font-size: 15px;
  padding: 10px;
  background-color: $primary;
  font-family: $font-family-primary;
  font-weight: $font-weight-semibold;
  color: white;

  #first-div {
    text-align: left;
  }

  #second-div {
    text-align: center;
  }

  #third-div {
    text-align: right;
  }


  @media (max-width: 576px) {
    #first-div,
    #third-div {
      text-align: center;
    }
  }


  a {
    cursor: pointer;
    color: white !important;
    border-bottom: 1px solid white;
  }
  p {
    margin: 0 !important;
  }
}
