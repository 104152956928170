ul{
    list-style-type: none;
    padding-left: 0;
}

.recruitment-img, .resource-outsourcing-img{
    height: 400px;
    width: auto;
    max-width: 100%;
    
     @media (max-width: 992px) {
        height: 350px;
    }
    
     @media (max-width: 768px) {
        height: 300px;
    }

}
