//
// _landing.scss
//

.layout-wrapper {
  &.landing {
    background-color: $card-bg;
    font-size: 15px;
  }
}

// helper

.section {
  padding: 90px 0;
  position: relative;

  @media (max-width: 767.98px) {
    padding: 50px 0;
  }
}

.icon-effect {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: rgba($success, 0.2);
    border-radius: 50%;
    bottom: 0;
    left: 0;
  }
}

// navbar

.navbar-landing {
  padding: 10px 0px;
  transition: all 0.5s ease;
  box-shadow: 0 1px 16px -2px rgba(56, 65, 74, 0.15);

  background-color: $card-bg;
  height: $header-height;
  margin-bottom: $header-height;
  @media (max-width: 991.98px) {
    padding: 0 8px;
  }

  .navbar-nav {
    .nav-item {
      .nav-link-public {
        font-weight: $font-weight-medium;
        transition: all 0.3s;
        font-family: $font-family-secondary;
        color: $primary;
        padding: 14px;
        font-size: 1.2rem;
        font-weight: bold;

        @media (max-width: 991.98px) {
          padding: 8px 0px;
        }

        @media (min-width: 992px) and (max-width: 1199.98px) {
          font-size: 1rem;
          padding: 0px 3px;
        }

        @media (min-width: 1200px) {
          font-size: 1.2rem;
          padding: 6px 13px;
        }

        &:hover,
        &.active,
        &:focus {
          color: $secondary !important;
        }
      }

      &.active {
        .nav-link-public {
          color: $success !important;
        }
      }
    }
  }

  @media (max-width: 1250px) {
    .navbar-brand img {
      height: 40px;
      width: auto;
    }
  }

  .nav-link-public.active {
    color: $success !important;
  }

  &.is-sticky {
    background-color: $card-bg;
    box-shadow: 0 1px 16px -2px rgba(56, 65, 74, 0.15);
  }
}

//navbar-light
.navbar-light {
  .navbar-brand {
    .card-logo-dark {
      display: none;
    }

    .card-logo-light {
      display: block;
    }
  }

  .navbar-nav {
    .nav-item {
      .nav-link-public {
        color: rgba($light, 0.75);
      }
    }
  }

  &.is-sticky {
    .navbar-nav {
      .nav-item {
        .nav-link-public {
          color: var(--#{$variable-prefix}dark);
        }
      }
    }

    .navbar-brand {
      .card-logo-dark {
        display: block;
      }

      .card-logo-light {
        display: none;
      }
    }
  }
}

// hero section

.hero-section {
  background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.5);

  .hero-shape-svg {
    svg {
      path {
        fill: $card-bg;
      }
    }
  }
}

.bg-overlay-pattern {
  background-image: url("../../images/landing/bg-pattern.png");
  background-color: transparent;
  background-position: center;
  background-size: cover;
  opacity: 0.2;
}

.demo-carousel {
  position: relative;
  z-index: 1;

  .carousel-item {
    .demo-item {
      background-color: $card-bg;
      padding: 8px;
      border-radius: 7px;
    }
  }

  .demo-img-patten-top {
    position: absolute;
    right: -50px;
    top: -16px;
    max-width: 230px;
  }

  .demo-img-patten-bottom {
    position: absolute;
    left: -70px;
    bottom: -50px;
    max-width: 230px;
  }
}

// client images
.client-images img {
  max-height: 45px;
  width: auto;
  margin: 12px auto;
  transition: all 0.4s;
}

// plan

.plan-box {
  max-width: 356px;
  margin-left: auto;
  margin-right: auto;
}

// work process
.process-card {
  position: relative;

  .process-arrow-img {
    position: absolute;
    left: 75%;
    top: 7%;
    width: 50%;
    opacity: 0.1;
  }
}

.footer-list {
  li {
    a {
      color: #9ba7b3;
      padding: 7px 0;
      display: block;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: rgba($white, 0.9);
      }
    }
  }
}

.footer-social-link {
  .avatar-title {
    color: #778089;
    background-color: rgba($white, 0.05);
    transition: all 0.3s ease;

    &:hover {
      color: $white;
      background-color: $primary;
    }
  }
}

// dark

[data-layout-mode="dark"] {
  .layout-wrapper.landing {
    .demo-img-patten-top,
    .demo-img-patten-bottom {
      opacity: 0.2;
    }

    footer.bg-dark {
      background-color: $card-bg !important;
    }
  }
}

#company-dropdown {
  color: #3e6a9f !important;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: "hkgrotesk", sans-serif;
  border: none;
  outline: none;
  padding-left: 0px;
}
#company-dropdown-title::after {
  display: none !important;
}
.nav-chevron::after {
  color: #ffc506 !important;
}

/* Decrease font size to 0.8rem on lg and xl screens */
@media (min-width: 992px) {
  #company-dropdown {
    font-size: 1rem;
    padding-left: 13px;
  }
}

/* Increase font size to 1rem only on xl screens */
@media (max-width: 1200px) {
  #company-dropdown {
    font-size: 1.2rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  #company-dropdown {
    font-size: 1rem;
  }
}

#company-dropdown:hover {
  color: #ffc506 !important;
  border: none;
  outline: none;
  transition: none;
}

.custom-li {
  text-align: left;
}

#toggler-icon {
  font-size: 40px;
  border: none;
}



