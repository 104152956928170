.training-list-card {
  background-color: rgba(217, 217, 217, 0.21);
  border: 1px solid $primary;
  margin: 10px;
  border-radius: 10px;

  h4 {
    font-family: "hkgrotesk_bold";
    font-weight: "bold";
    font-size: 24px;
    text-shadow: 0px 2px 5.3px 0px rgba(0, 0, 0, 0.25) !important;
  }
  .training-card-wrapper {
    display: grid;
    grid-template-columns: 1fr; /* Adjust as needed */
    grid-template-rows: auto auto;
    gap: 10px; /* Adjust the gap as needed */

    /* Optional: Set a max-width for the grid container */
    max-width: 500px;
    margin: auto;
  }
  @media only screen and (min-width: 568px) {
    .training-card-wrapper {
      display: grid;
      grid-template-columns: 2fr 1fr; /* Adjust as needed */
      grid-template-rows: auto auto;
      gap: 10px; /* Adjust the gap as needed */

      /* Optional: Set a max-width for the grid container */
      max-width: 500px;
      margin: auto;
    }
  }
  @media only screen and (min-width: 768px) {
    .training-card-wrapper {
      display: grid;
      grid-template-columns: 3fr 2fr 3fr; /* Adjust as needed */
      grid-template-rows: auto auto;
      gap: 10px; /* Adjust the gap as needed */

      /* Optional: Set a max-width for the grid container */
      max-width: 700px;
      margin: auto;
    }
  }
  @media only screen and (min-width: 992px) {
    .training-card-wrapper {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr 1fr; /* Adjust as needed */
      grid-template-rows: auto auto;
      gap: 10px; /* Adjust the gap as needed */

      /* Optional: Set a max-width for the grid container */
      max-width: 1033px;
      margin: auto;
    }
  }
  @media only screen and (min-width: 1200px) {
    .training-card-wrapper {
      display: grid;
      grid-template-columns: 4fr 2fr 4fr 2fr 2fr 4fr; /* Adjust as needed */
      grid-template-rows: auto auto;
      gap: 10px; /* Adjust the gap as needed */

      /* Optional: Set a max-width for the grid container */
      max-width: 1068px;
      margin: auto;
    }
  }
  .training-card-icon {
    width: 20px;
    height: 20px;
  }
  .training-info {
    text-shadow: 0px 2px 5.3px 0px rgba(0, 0, 0, 0.25) !important;
    font-weight: 500;
    font-size: 16px;
  }
  .training-card-btn {
    padding: 5px 15px;
    border-radius: 10px;
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
  }
}

//Grid Card
.training-grid-card {
  background-color: rgba(217, 217, 217, 0.21);
  border: 1px solid $primary;
  margin: 10px;
  border-radius: 10px;

  .training-card-btn {
    padding: 5px 15px;
    border-radius: 10px;
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
  }
  .training-card-icon {
    width: 20px;
    height: 20px;
  }
  .training-info {
    text-shadow: 0px 2px 5.3px 0px rgba(0, 0, 0, 0.25) !important;
    font-weight: 500;
    font-size: 16px;
  }
}

//Card Style for Training without Schedule
.public-training-list-card {
  background-color: rgba(217, 217, 217, 0.21);
  border: 1px solid $primary;
  margin: 10px;
  border-radius: 10px;

  .training-card-btn {
    padding: 5px 15px;
    border-radius: 10px;
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
  }
  .training-info {
    text-shadow: 0px 2px 5.3px 0px rgba(0, 0, 0, 0.25) !important;
    font-weight: 500;
    font-size: 16px;
  }
}
.trainer-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #dedede;
}
.trainer-avatar img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
