
.chat-sidebar{
  box-shadow: 4px 0px 8px -3px rgba(0,0,0,0.3);
  padding: 0;

  .search-div{
    border-bottom: 1px solid lightgray;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 20px;

    .form-control{
      padding: 0.5rem 0.9rem;
    }
  }

  .unread-messages-circle{
    background-color: $secondary;
    border-radius: 50%;
    color: black !important;
    width: 20px; 
    height: 20px; 
    display: flex; 
    align-items: center;
    justify-content: center
  }
  
}


.chat-list {
    background-color: white;    
    height: calc(100vh - $header-height - $breadcrumb-height - 60px);
    overflow-y: scroll;
    
    h5{
      width: 70%;
    }

    li {
      cursor: pointer;
      padding: 20px;
      text-align: left;

      img{
          border-radius: 50%;
          height: 50px;
          width: 50px;
      }

      &:not(.active):hover {
        background-color: rgba($primary, 50);
        h5, p, span{
          color: white !important;
        }
      }
    }
  
    .active {
      background-color: $primary;
      h5, p, span{
        color: white;
      }
  
      &:hover {
        background-color: $primary; /* Optionally, you can repeat the background color to maintain consistency */
      }
    }
    
  }
  

.chat-box{
  
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  height: calc(100vh - $header-height - $breadcrumb-height - $chat-type-height);
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  font-weight: 500;
  transition: 0.2s;

  .message{
    color: white;
    padding: 5px 20px 5px 8px;
    border: 1px solid transparent;
    border-radius: 10px;
    margin-top: 1%;
    text-align: left;
    white-space: pre-wrap;
    transition: 0.2s;
    
  }
  
  
  .received-message{
    
    float: left;
    background-color: $primary;
    margin-right: 50%;
    
    .time{
      text-align: right;
      margin-top: 2px;
      margin-right: -10px;
      color: lightblue;
      font-size: 10px;
    }
  }
  
  .sent-message{
    background-color: #299cdb;
    float: right;
    margin-left: 50%;
    cursor: pointer;
    transition: 0.5s; // Add transition property 

    &:hover {
      cursor: pointer;
      background-color: #f06548;
      .trash-icon {
        clear: both;
        display: inline-block;
        opacity: 1;
      }
    }
  
    .trash-icon {
      margin-right: 5px;
      opacity: 0;
    }

  }

  .time{
    text-align: right;
    margin-top: 2px;
    margin-right: -10px;
    font-size: 10px;
  }

  .day {
    text-align:center; 
    clear: both;
    color: grey;
    padding-top: 1rem;

    &:before,
    &:after {
      color: grey;
      background-color: grey;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 20%;
    }

    span {
      margin: 0 10px;
    }
  }

}

.chat-type{
  display: flex;
  align-items: center;
  background-color: $primary;
  min-height: $chat-type-height;
  border-bottom: 1px solid $secondary;
  i{
    font-size: 1.3rem;
    color: white;
    cursor: pointer;
  }
}

.options-btn{
  padding: 0 10px;

  &:active,
  &:hover{
    border-color: transparent !important;
    box-shadow: 2px 4px 16px 0px rgba(0,0,0,0.25) !important;
  }

  p{
    margin-bottom: 0;  
  }
}


.scroll-to-bottom {
    box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.30);
    cursor: pointer;
    position: absolute;
    padding: 10px 13px;
    background-color: #fdc800;
    width: fit-content;
    right: 50px;
    bottom: 80px;
    border-radius: 50%;
}
