.candidate-signup {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
    url("../../../../images/authentication/background.jpg");
  background-position: bottom;
  background-size: cover;
  min-height: calc(100vh - 132.5px);
}

.employer-signup {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
    url("../../../../images/authentication/candidate-signup.jpg");
  background-position: bottom;
  background-size: cover;
}

.sign-in {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
    url("../../../../images/authentication/sign-in.jpg");
  background-position: bottom;
  background-size: cover;
}

.admin-sign-in {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
    url("../../../../images/authentication/admin-sign-in.jpg");
  background-position: bottom;
  background-size: cover;
}

.contact-us {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
    url("../../../../images/contact-us/background.jpg");
  background-size: cover;
  background-position: top;
}

.forgot-password {
  background-image: url("../../../../images/reset-password/forgot-password-bg.jpg");
  background-size: cover;
  background-position: top;
  min-height: calc(100vh - $header-height);
}

.password-reset {
  background-image: url("../../../../images/reset-password/reset-password-bg.jpg");
  background-size: cover;
  background-position: top;
  min-height: calc(100vh - $header-height);
}

// @media (min-width: "568px") {
.sign-up-container,
.sign-in-container,
.contact-us-container {
  min-height: calc(100vh - $header-height);
}
// }

.form-wrapper {
  margin-top: $header-height;
  .row {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }

  .form-div {
    padding: 10px 0;
  }

  .col-sm-12,
  .col-lg-8,
  .col-lg-6 {
    border-radius: 5px;
    padding: 20px 50px;
  }

  label {
    color: $primary;
  }

  .link {
    margin-bottom: 3px;
    border-bottom: 1px solid $primary;
    font-weight: $font-weight-bold;
  }

  @media (max-width: 576px) {
    .row {
      padding: 30px;
    }

    .col-sm-12,
    .col-lg-6 {
      padding: 30px;
    }
  }
}

.form-type {
  // display: flex;
  // justify-content: space-around;
  // height: 50px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 10px;

  a {
    transition: 0.1s linear;
    color: $primary !important;
    cursor: pointer;
    margin-bottom: 5px;
    font-size: 1rem;
    border: 1px solid #3e6a9f;
    padding: 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      border: 1px solid transparent;
      font-weight: bold;
      background: $primary;
      font-size: 1.4rem;
      color: white !important;
      box-shadow: 1px 1px 2px 1px rgb(225 224 224);
    }
  }

  @media only screen and (min-width: "568px") {
    & {
      a {
        padding: 5px;
        font-size: 1.2rem;
      }
    }
  }
  @media only screen and (min-width: "768px") {
    & {
      grid-template-columns: 1fr 1fr 1fr;

      a {
        padding: 10px;
      }
    }
  }
}

.social-button {
  font-size: 16px !important;
}

@media (max-width: 576px) {
  .social-button {
    font-size: 12px !important;
  }
}

.or-text {
  position: relative;
  color: #0006;
  margin-bottom: 0;
  &:after {
    content: "";
    display: block;
    width: 40%;
    height: 1px;
    background: #0006;
    right: 0;
    top: 50%;
    position: absolute;
  }
  &:before {
    content: "";
    display: block;
    width: 40%;
    height: 1px;
    background: #0006;
    top: 50%;
    left: 0;
    position: absolute;
  }
}

.main-container {
  margin-top: 1px;
  margin-bottom: 50px;
  border-radius: 5px;
}

.SpinnerStyling {
  width: 3rem;
  height: 3rem;
  border-width: 0.4rem;
  margin-top: 30px;
}

.training-div {
  padding: 10px 20px 10px 20px;
  border: 1px solid $primary;
  border-radius: 10px;
  margin: 10px;
  overflow: hidden;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.HeadingStyling {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
}

.BtnStyling {
  width: 33%;

  @media (max-width: 768px) {
    width: 45%;
  }
}

.button-container {
  display: inline-block;
}

.show-per-page-label {
  color: #3e6a9f;
}

.SelectStyling {
  height: 38px;
  width: 50px;
}

.BtnStyling2 {
  height: 40px;
  width: 40px;
}

.BtnStyling3 {
  height: 40px;
  width: 80px;
}

@media (max-width: 767px) {
  .navbar-brand img {
    height: 60px;
  }
}

@media (max-width: 575px) {
  .navbar-brand img {
    height: 50px;
  }
}
