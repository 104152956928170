.pricing{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url("../../../../images/pricing/pricing-bg.jpg");
    background-size: cover;
    background-position: top;

    .main-container{
        margin-top: 50px;
        margin-bottom: 50px;
        border-radius: 5px;
    }
}