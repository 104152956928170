// 
// _page-head.scss
// 


.page-title-box {
    padding: 10px $grid-gutter-width;
    background-color: $header-bg;   
    height: $breadcrumb-height;
    border-bottom: 1px solid black;
    // margin: -23px (-$grid-gutter-width) 0 (-$grid-gutter-width);

    .breadcrumb {
        background-color: $header-bg;
        height: calc($header-height * 0.5);
        border-bottom: 1px solid black !important;   
    }

    h3{
        font-size: 1.2rem !important;
        font-weight: $font-weight-bold !important;
    }
   
}

[data-layout="horizontal"] {
    .page-title-box {
        padding: 1.2rem 0;
        background-color: transparent !important;
        border-bottom: none;
        border-top: none;
        box-shadow: none;
        margin: 0;

        @media (min-width: 1024.1px) {
            margin: -19px 0 0 0;
        }
    }
}

[data-layout="vertical"] {
    &[data-layout-style="detached"]{
        .page-title-box {
            padding: 1.2rem 0;
            background-color: transparent !important;
            border-bottom: none;
            border-top: none;
            box-shadow: none;
            margin: 0;
    
            @media (min-width: 1024.1px) {
                margin: -19px 0 0 0;
            }
        }
    }
}