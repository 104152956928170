
.testimonial-section{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    background: 'rgb(2,0,36)';
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(62,106,159,1) 35%, rgba(0,45,100,1) 100%);
    // border: 1px solid red;
    


}


.carousel-inner{
    overflow: visible!important;
    // height: auto;
    width: auto;
}

.carousel-container{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: 'rgb(2,0,36)';
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(62,106,159,1) 35%, rgba(0,45,100,1) 100%);
    padding: 4rem;
     


  }

  .carousel-item-container {
    // margin: 0 1rem; 
    // flex: 0 0 auto; 
    // width: calc(100% - 2rem);
    // border: 1px solid red;
    padding: 0 1rem;
  }

  
  .cardBody{
    width: 60vw;
    height: 23rem;
    // height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-color: rgba(255, 255, 255, 0);
    // backdrop-filter: blur(10px);
    // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    // border-radius: 8px;
    // padding: 1rem;
    text-align: center;
    position: relative;
    overflow: visible;
    margin-left: auto;
    margin-right: auto;
    margin-top: -2rem;
    // border: 1px solid red;
    transition: height 1s ease-in-out;   
  }
  .dynamic-text-container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: -0.5rem;
    // background-color: red;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    // padding: 1rem;
    text-align: center;
    // position: relative;
    overflow: visible;
    // margin-left: auto;
    // margin-right: auto;
    margin: auto;
    padding: 1rem;
    // border: 1px solid red;
  }
  .readmore{
    width: 60vw!important;
    height: auto!important;
    overflow-y: visible;
}

  
  .card-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -2.7rem;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    // border: 2px solid white;
    background-color: #f3f3f9;
    border: 1px solid #e9ebec;
    z-index: 999;
  }



  
  .card-title-container{
    position: absolute;
    top: 5.66rem;
    // border: 1px solid red;
  }
  
  .card-title{
    color: rgba(62, 106, 159);
  }
  
  .card-subtitle{
    color: rgba(62, 106, 159, 0.8);
    font-weight: 600;
    font-size: 1rem;
  }
  
  .card-text{
    margin-top: 8rem;
    padding: 0 1rem;
    color: rgba(62, 106, 159, 0.8);
    // overflow-y: hidden;
    // position: relative;
    height: auto;
    // border: 1px solid red;
    margin-bottom: 0;
    // padding: 1rem;
  }

  .card-text p{
    text-align: left;
    font-size: 1rem;
    // max-height: 5rem;
    // margin-bottom: 0rem !important;
  }
  

  .show-more {
    max-height: none;
    margin-top: 9rem;
    padding: 0 1rem;
    color: rgba(62, 106, 159, 0.8);
    overflow-y: visible;
    position: relative;
}

.read-more-btn {
    background: transparent;
    border: none;
    color: rgba(62, 106, 159);
    cursor: pointer;
    margin-top: 0.5rem;
    font-size: 1rem;
}




// @media(max-width: 780px){
//     .cardBody{
//         width: 70vw!important;
//         height: 24rem!important;
//     }

//     .carousel-item-container {
        
//         padding: 0 4rem;
//     }

// }
@media(max-width: 900px){
    .cardBody{
        width: 77vw!important;
        height: 28rem!important;
        margin-top: -2rem!important;
    }

    .carousel-item-container {
        
        padding: 0 4rem;
    }

}



// @media(max-width: 650px){
//     .cardBody{
//         width: 70vw!important;
//         height: auto!important;
//     }
//     .afterReadMore{
//         width: 90vw!important;
//         height: auto!important;
//     }
// }


@media(max-width: 499px){
    .cardBody{
        width: 70vw!important;
        height: auto!important;
        // border: 1px solid red;
        margin-top: -1rem!important;

    }

    .card-text p{
        text-align: justify;
        font-size: 1rem;
      }

    
}