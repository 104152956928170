.my-jobs-ul{
    list-style-type: none;
    padding-left: 0;
    text-align: left;
    font-size: 1rem;
    border-bottom: 1px solid black;
    background-color: $header-bg;
    min-height: calc($header-height * 0.5);
    padding: 10px 0;

    .row{
        height: 100%;
        display: flex;
        align-items: center;
    }

    ul{
        margin-bottom: 0;
       
    }

    li{
        display: inline;
        margin: 5px 5px 5px 0;        
        font-weight: $font-weight-medium;
        cursor: pointer;
        padding-bottom: 5px;
        padding: 10px;
        transition: all 0.1s ease-out;
        border-radius: 5px;
        padding: 5px 10px;

        &:hover{

            background: #3e6a9f;
            color: white;
        }
    }

    li.active{
        background: #3e6a9f;
        color: white;
    }
}
