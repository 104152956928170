.candidate-profile {
  margin-top: 1.5rem;
  border-radius: 5px;
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 10px;

  h2 {
    text-align: left;
  }

  p {
    text-align: justify;
  }

  i.fa {
    border: 1px solid transparent;
    border-radius: 50%;
    transition: 0.2s ease-in;
    cursor: pointer;
  }

  .badge {
    cursor: pointer;
    transition: 0.2s;
  }

  i.primary {
    color: $primary;
    background: rgb(245, 246, 255);

    &:hover {
      background-color: $primary;
      color: white;
    }
  }

  i.success {
    color: $success;
    background: rgb(242 255 240);

    &:hover {
      background-color: $success;
      color: white;
    }
  }

  i.danger {
    color: $danger;
    background: rgb(248 240 240);

    &:hover {
      background-color: $danger;
      color: white;
    }
  }

  i.fa-camera {
    padding: 8px;
  }

  i.fa-plus,
  i.fa-check,
  i.fa-pencil,
  i.fa-key,
  i.fa-download,
  i.fa-trash {
    padding: 5px;
  }

  i.fa-xmark {
    padding: 5px 8px;
    background: rgb(255 204 204 / 35%);
    border: 1px solid transparent;
    border-radius: 50%;
    transition: 0.2s ease-in;
    cursor: pointer;

    &:hover {
      background-color: $danger;
      color: white !important;
    }
  }

  i.no-styling {
    padding: 0;
    background: white;
  }

  .work-experience,
  .education {
    text-align: left;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .candidate-projects .accordion-button,
  .work-experience .accordion-button {
    padding: 10px 20px;
  }

  .candidate-projects .edit-option {
    position: absolute;
    top: 16px;
    right: 60px;
    z-index: 2;
  }

  // .candidate-projects:not(:last-child)::after {
  //   border-bottom: 2px solid #ccc;
  //   content: "";
  //   display: block;
  //   max-width: 600px;
  //   position: absolute;
  //   bottom: 7px;
  //   left: 50%;
  //   right: 0;
  //   transform: translateX(-50%);
  // }

  .candidate-projects-wrapper {
    text-align: left;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }



  .custom-quill .ql-container {
    border: 1px solid #3e6a9f !important;
    border-radius: 4px;
  }

  .custom-quill .ql-toolbar {
    border: 1px solid #3e6a9f !important;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
  }

  .designation,
  .institute {
    margin-bottom: 0.2rem;
    font-weight: 600;
  }

  .company-location,
  .degree,
  .location {
    margin-bottom: 0;
    font-weight: 500;
  }

  .tenure {
    opacity: 0.7;
    margin-bottom: 0;
  }

  .description {
    margin-top: 0.5rem;
  }

  .detail-card:last-child {
    margin-bottom: 0;
  }
}

.candidate-profile-document {
  margin-top: 1.5rem;
  border-radius: 5px;
  padding: 20px 10px;

  h2 {
    text-align: left;
  }

  p {
    text-align: justify;
  }

  i.fa {
    border: 1px solid transparent;
    border-radius: 50%;
    transition: 0.2s ease-in;
    cursor: pointer;
  }

  .badge {
    cursor: pointer;
    transition: 0.2s;
  }

  i.primary {
    color: $primary;
    background: rgb(245, 246, 255);

    &:hover {
      background-color: $primary;
      color: white;
    }
  }

  i.success {
    color: $success;
    background: rgb(242 255 240);

    &:hover {
      background-color: $success;
      color: white;
    }
  }

  i.danger {
    color: $danger;
    background: rgb(248 240 240);

    &:hover {
      background-color: $danger;
      color: white;
    }
  }

  i.fa-camera {
    padding: 8px;
  }

  i.fa-plus,
  i.fa-check,
  i.fa-pencil,
  i.fa-key,
  i.fa-download,
  i.fa-trash {
    padding: 5px;
  }

  i.fa-xmark {
    padding: 5px 8px;
    background: rgb(255 204 204 / 35%);
    border: 1px solid transparent;
    border-radius: 50%;
    transition: 0.2s ease-in;
    cursor: pointer;

    &:hover {
      background-color: $danger;
      color: white !important;
    }
  }

  i.no-styling {
    padding: 0;
    background: white;
  }

  .work-experience,
  .education {
    text-align: left;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .designation,
  .institute {
    margin-bottom: 0.2rem;
    font-weight: 600;
  }

  .company-location,
  .degree,
  .location {
    margin-bottom: 0;
    font-weight: 500;
  }

  .tenure {
    opacity: 0.7;
    margin-bottom: 0;
  }

  .description {
    margin-top: 0.5rem;
  }
}