.slide-up {
    animation: slideUp 0.8s ease-in-out;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateY(-40px); /* Adjust the distance you want to slide up */
  }
  
  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(-40px);
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }