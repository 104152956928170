.company-profile{
    
    margin-top: 0.8rem;

    &:first-of-type{
        border-bottom: 1px solid $primary;
    }


    h2{
        text-align: left;
        
    }

    p{
       text-align: justify;
    }

    i{
        border: 1px solid transparent;
        border-radius: 50%;
        transition: 0.2s ease-in;
        cursor: pointer;
        padding: 8px;
    }

    i.primary{
        color: $primary;
        background: rgb(245, 246, 255);
        &:hover{
            background-color: $primary;
            color: white;
        }
    }

    i.danger{
        color: red;
        background: rgb(255 237 237);
        &:hover{
            background-color: $red;
            color: white;
        }
    }

    i.success{
        color: green;        
        background: rgb(243 255 237);
        &:hover{
            background-color: green;
            color: white;
        }
    }

    
    i.la-times{
        
        padding: 8px;
        background: rgb(255 204 204 / 35%);
        border: 1px solid transparent;
        border-radius: 50%;
        transition: 0.2s ease-in;
        cursor: pointer;

        &:hover{
            background-color: $danger;
            color: white;
        }
    }

    .fa-envelope, .fa-globe, .fa-phone, .fa-map-pin{
        padding: 2px !important;
    }

    
}

.skills-table{
    border-collapse: 'collapse';
    width: 100%;
    border-radius: 5px;
    
      th{
        background-color: $primary;
        color: white;
        font-weight: bold;
        padding: 8px;
        border: 1px solid $primary;
        text-align: center;
      }
    
      td{
        border: 1px solid $primary;
        padding: 8px,
      };
    
}