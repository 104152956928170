.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}









/* *,
*::after,
*::before {
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 70%;
}
body {
  font-size: 1.6rem;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: rgb(204, 204, 204);
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 10vh;
  background: rgb(0, 33, 65);
}
.nav__brand {
  text-transform: uppercase;
}

.nav__menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3rem;
}
.nav__toggler {
  display: none;
}
.nav__toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background: rgb(204, 204, 204);
  transition: 0.3s ease-in;
}

@media screen and (max-width: 768px) {
  .nav__toggler {
    display: block;
    cursor: pointer;
  }
  .nav__menu {
    position: fixed;
    top: 7vh;
    right: 0;
    height: 93vh;
    width: 50vw;
    background: rgb(0, 33, 65);
    flex-direction: column;
    transform: translateX(100%);
    transition: 0.5s ease-in;
  }
}

.nav__active {
  transform: translateX(0%);
}


.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 5px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}



.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .sign-in-button,
  .sign-up-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .sign-in-button {
    background-color: #3498db;
    color: #fff;
  }
  
  .sign-up-button {
    background-color: #2ecc71;
    color: #fff;
    margin-right: 20px;
  }

@media screen and (max-width: 768px) {
    .button-container {
      flex-direction: column; 
    }
    
    .sign-in-button,
    .sign-up-button {
      width: auto;
    }
    .sign-up-button {
      margin-right: 0px;
    }
  } */