.quill.ql-error {
    .ql-toolbar {
      border-left: 1px solid red;
      border-right: 1px solid red;
      border-top: 1px solid red;
    }
    .ql-container {
      border-left: 1px solid red;
      border-right: 1px solid red;
      border-bottom: 1px solid red;
    }
  }