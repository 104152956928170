.contact-us-page {
  margin-top: $header-height;

  .title-section {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("../../images/contact-us//background.jpg");
    padding: 80px 0;
    text-align: center;
    background-size: cover;
  }
}

.demo-page{

  .title-section{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
    url("../../images/demo-page.jpg");
    padding: 80px 0;
    text-align: center;
    background-size: cover;
    background-position: 0 60%;
    background-repeat: no-repeat;
  }

}

.pricing-page {
  margin-top: $header-height;

  .title-section {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("../../images/pricing/pricing-bg.jpg");
    padding: 80px 0;
    text-align: center;
    background-size: cover;
    background-position: center;
  }
}



.about-us-page {
  margin-top: $header-height;

  .title-section {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("../../images//about-us/about-us.jpg");
    padding: 80px 0;
    text-align: center;
    background-size: cover;
  }
}

.how-it-works-page {
  margin-top: $header-height;

  .title-section {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("../../images/how-it-works/how-it-works.jpg");
    padding: 80px 0;
    text-align: center;
    background-size: cover;
  }
}

.investors-club-page {
  margin-top: $header-height;

  .title-section {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("../../images/investors-club/investors-club-bg.jpg");
    padding: 80px 0;
    text-align: center;
    background-size: cover;
  }
}

.counselling-page {
  margin-top: $header-height;

  .title-section {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("../../images/counselling/counselling-bg.jpg");
    padding: 80px 0;
    text-align: center;
    background-size: cover;
    background-position: center;
  }
}

.resource-outsourcing-page {
  margin-top: $header-height;

  .title-section {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("../../images/resource-outsourcing/resource-outsourcing.jpg");
    padding: 80px 0;
    text-align: center;
    background-size: cover;
    background-position: top;
  }
}

.campus-ambassadors-page {
  margin-top: $header-height;

  .title-section {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("../../images/campus-ambassadors/bg-2.jpg");
    padding: 80px 0;
    text-align: center;
    background-size: cover;
    background-position: right;
  }
}

.trainings-page {
  margin-top: $header-height;
  .title-section {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("../../images/training/training-bg.jpg");
    padding: 80px 0;
    text-align: center;
    background-size: cover;
    background-position: center;
  }
}

.careers-page {
  margin-top: $header-height;

  .title-section {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("../../images/careers/careers.jpg");
    padding: 80px 0;
    text-align: center;
    background-size: cover;
    background-position: center;
  }
}


.terms-conditions-page {
  margin-top: $header-height;
  .title-section {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("../../images/terms-conditions/terms-conditions-bg.jpg");
    padding: 80px 0;
    text-align: center;
    background-size: cover;
    background-position: bottom;
  }
}


.qr-code{
  width: 100px
}

.social-media-icon{
  transition: 0.2s;
  width: 40px;

    &.youtube{
    width: 50px;
  }

  &:hover{
    transform: scale(1.01);
  }
}